/*
   12 Column Grid
   -----------------------------------------------------------------------------
   This more or less copies what Bootstrap uses
*/

$grid--gutter: 14px;
$grid--form-gutter: 6px;

$grid--breakpoint-sm: 768px;
$grid--breakpoint-md: 992px;
$grid--breakpoint-lg: 1200px;

$grid--col-1: 8.33333333%;
$grid--col-2: 16.66666667%;
$grid--col-3: 25%;
$grid--col-4: 33.33333333%;
$grid--col-5: 41.66666667%;
$grid--col-6: 50%;
$grid--col-7: 58.33333333%;
$grid--col-8: 66.66666667%;
$grid--col-9: 75%;
$grid--col-10: 83.33333333%;
$grid--col-11: 91.66666667%;
$grid--col-12: 100%;

.row {
  margin-left: -$grid--gutter;
  margin-right: -$grid--gutter;

  &:before,
  &:after {
    display: table;
    content: ' ';
  }
}

.panel-body form {
  margin-left: -$grid--form-gutter;
  margin-right: -$grid--form-gutter;
  width: calc(100% + #{$grid--form-gutter * 2});
  display: inline-block;
}

.form-group-submit {
  margin-top: 30px;
}

.col {
  &-xs,
  &-sm,
  &-md,
  &-lg {
    &-12,
    &-11,
    &-10,
    &-9,
    &-8,
    &-7,
    &-6,
    &-5,
    &-4,
    &-3,
    &-2,
    &-1 {
      position: relative;
      float: left;
      min-height: 1px;
      padding-left: $grid--gutter;
      padding-right: $grid--gutter;

      &.form-group {
        padding-left: $grid--form-gutter;
        padding-right: $grid--form-gutter;
      }
    }
  }
}

//  Extra Small Screen Grid
//  ----------------------------------------------------------------------------
.col-sm,
.col-md,
.col-lg {
  &-12,
  &-11,
  &-10,
  &-9,
  &-8,
  &-7,
  &-6,
  &-5,
  &-4,
  &-3,
  &-2,
  &-1 {
    width: $grid--col-12;
  }
}
.col-xs {
  &-1 { width: $grid--col-1; }
  &-2 { width: $grid--col-2; }
  &-3 { width: $grid--col-3; }
  &-4 { width: $grid--col-4; }
  &-5 { width: $grid--col-5; }
  &-6 { width: $grid--col-6; }
  &-7 { width: $grid--col-7; }
  &-8 { width: $grid--col-8; }
  &-9 { width: $grid--col-9; }
  &-10 { width: $grid--col-10; }
  &-11 { width: $grid--col-11; }
  &-12 { width: $grid--col-12; }
}
.col-xs-offset {
  &-1 { margin-left: $grid--col-1; }
  &-2 { margin-left: $grid--col-2; }
  &-3 { margin-left: $grid--col-3; }
  &-4 { margin-left: $grid--col-4; }
  &-5 { margin-left: $grid--col-5; }
  &-6 { margin-left: $grid--col-6; }
  &-7 { margin-left: $grid--col-7; }
  &-8 { margin-left: $grid--col-8; }
  &-9 { margin-left: $grid--col-9; }
  &-10 { margin-left: $grid--col-10; }
  &-11 { margin-left: $grid--col-11; }
}

//  Small Screen Grid
//  ----------------------------------------------------------------------------
@media screen and (min-width: $grid--breakpoint-sm) {
  .col-sm {
    &-1 { width: $grid--col-1; }
    &-2 { width: $grid--col-2; }
    &-3 { width: $grid--col-3; }
    &-4 { width: $grid--col-4; }
    &-5 { width: $grid--col-5; }
    &-6 { width: $grid--col-6; }
    &-7 { width: $grid--col-7; }
    &-8 { width: $grid--col-8; }
    &-9 { width: $grid--col-9; }
    &-10 { width: $grid--col-10; }
    &-11 { width: $grid--col-11; }
    &-12 { width: $grid--col-12; }
  }
  .col-sm-offset {
    &-1 { margin-left: $grid--col-1; }
    &-2 { margin-left: $grid--col-2; }
    &-3 { margin-left: $grid--col-3; }
    &-4 { margin-left: $grid--col-4; }
    &-5 { margin-left: $grid--col-5; }
    &-6 { margin-left: $grid--col-6; }
    &-7 { margin-left: $grid--col-7; }
    &-8 { margin-left: $grid--col-8; }
    &-9 { margin-left: $grid--col-9; }
    &-10 { margin-left: $grid--col-10; }
    &-11 { margin-left: $grid--col-11; }
  }
}

//  Medium Screen Grid
//  ----------------------------------------------------------------------------
@media screen and (min-width: $grid--breakpoint-md) {
  .col-md {
    &-1 { width: $grid--col-1; }
    &-2 { width: $grid--col-2; }
    &-3 { width: $grid--col-3; }
    &-4 { width: $grid--col-4; }
    &-5 { width: $grid--col-5; }
    &-6 { width: $grid--col-6; }
    &-7 { width: $grid--col-7; }
    &-8 { width: $grid--col-8; }
    &-9 { width: $grid--col-9; }
    &-10 { width: $grid--col-10; }
    &-11 { width: $grid--col-11; }
    &-12 { width: $grid--col-12; }
  }
  .col-md-offset {
    &-1 { margin-left: $grid--col-1; }
    &-2 { margin-left: $grid--col-2; }
    &-3 { margin-left: $grid--col-3; }
    &-4 { margin-left: $grid--col-4; }
    &-5 { margin-left: $grid--col-5; }
    &-6 { margin-left: $grid--col-6; }
    &-7 { margin-left: $grid--col-7; }
    &-8 { margin-left: $grid--col-8; }
    &-9 { margin-left: $grid--col-9; }
    &-10 { margin-left: $grid--col-10; }
    &-11 { margin-left: $grid--col-11; }
  }
}

//  Large Screen Grid
//  ----------------------------------------------------------------------------
@media screen and (min-width: $grid--breakpoint-lg) {
  .col-lg {
    &-1 { width: $grid--col-1; }
    &-2 { width: $grid--col-2; }
    &-3 { width: $grid--col-3; }
    &-4 { width: $grid--col-4; }
    &-5 { width: $grid--col-5; }
    &-6 { width: $grid--col-6; }
    &-7 { width: $grid--col-7; }
    &-8 { width: $grid--col-8; }
    &-9 { width: $grid--col-9; }
    &-10 { width: $grid--col-10; }
    &-11 { width: $grid--col-11; }
    &-12 { width: $grid--col-12; }
  }
  .col-lg-offset {
    &-1 { margin-left: $grid--col-1; }
    &-2 { margin-left: $grid--col-2; }
    &-3 { margin-left: $grid--col-3; }
    &-4 { margin-left: $grid--col-4; }
    &-5 { margin-left: $grid--col-5; }
    &-6 { margin-left: $grid--col-6; }
    &-7 { margin-left: $grid--col-7; }
    &-8 { margin-left: $grid--col-8; }
    &-9 { margin-left: $grid--col-9; }
    &-10 { margin-left: $grid--col-10; }
    &-11 { margin-left: $grid--col-11; }
  }
}
