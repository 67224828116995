/*
   Influx Color Palette
   -----------------------------------------------------------------------------
*/

// Nuetrals (Dark to Light)
$g0-obsidian: #0f0e15;
$g1-raven: #1c1c21;
$g2-kevlar: #202028;
$g3-castle: #292933;
$g4-onyx: #31313d;
$g5-pepper: #383846;
$g6-smoke: #434453;
$g7-graphite: #545667;
$g8-storm: #676978;
$g9-mountain: #757888;
$g10-wolf: #8e91a1;
$g11-sidewalk: #999dab;
$g12-forge: #a4a8b6;
$g13-mist: #bec2cc;
$g14-chromium: #c6cad3;
$g15-platinum: #d4d7dd;
$g16-pearl: #e7e8eb;
$g17-whisper: #eeeff2;
$g18-cloud: #f6f6f8;
$g19-ghost: #fafafc;
$g20-white: #ffffff;

// Telegraf (Dark to Light)
$c-basalt: #2F1F29;
$c-ruby: #BF3D5E;
$c-fire: #DC4E58;
$c-fire-disabled: #6F3943;
$c-curacao: #F95F53;
$c-curacao-disabled: #EADAD8;
$c-dreamsicle: #FF8564;
$c-tungsten: #FFB6A0;
$c-marmelade: #FFDCCF;
$c-flan: #FFF7F4;

// InfluxDB (Dark to Light)
$c-abyss: #182838;
$c-sapphire: #326BBA;
$c-ocean: #4591ED;
$c-ocean-disabled: #32547F;
$c-pool: #22ADF6;
$c-pool-disabled: #C9E0ED;
$c-laser: #00C9FF;
$c-hydrogen: #6BDFFF;
$c-neutrino: #BEF0FF;
$c-yeti: #F0FCFF;

// Chronograf (Dark to Light)
$c-shadow: #1F2039;
$c-void: #311F94;
$c-amethyst: #513CC6;
$c-amethyst-disabled: #484281;
$c-star: #7A65F2;
$c-star-disabled: #D6D5ED;
$c-comet: #9394FF;
$c-potassium: #B1B6FF;
$c-moonstone: #C9D0FF;
$c-twilight: #F2F4FF;

// Kapacitor (Dark to Light)
$c-gypsy: #152B2D;
$c-emerald: #108174;
$c-viridian: #32B08C;
$c-viridian-disabled: #2B6058;
$c-rainforest: #4ED8A0;
$c-rainforest-disabled: #CFE6E1;
$c-honeydew: #7CE490;
$c-krypton: #A5F3B4;
$c-wasabi: #C6FFD0;
$c-mint: #F2FFF4;

// Warnings (Dark to Light)
$c-oak: #3F241F;
$c-topaz: #E85B1C;
$c-tiger: #F48D38;
$c-pineapple: #FFB94A;
$c-thunder: #FFD255;
$c-sulfur: #FFE480;
$c-daisy: #FFF6B8;
$c-banana: #FFFDDE;
