/*
    "Confirm or Cancel" Buttons
    ----------------------------------------------------------------------------
*/

.confirm-or-cancel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  .confirm-or-cancel--confirm {
    order: 2;
    margin-left: 4px;
  }
  .confirm-or-cancel--cancel {
    order: 1;
  }

  &.reversed {
    .confirm-or-cancel--confirm {
      order: 1;
      margin-left: 0;
    }
    .confirm-or-cancel--cancel {
      order: 2;
      margin-left: 4px;
    }
  }
}
