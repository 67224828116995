/*
  Styles for the Template Variables Manager Panel
  ------------------------------------------------------
  Accessed via Dashboards
*/

$tvmp-panel-max-width: 1300px;
$tvmp-gutter: 30px;
$tvmp-min-height: 150px;
$tvmp-max-height: calc(100% - 90px);
$tvmp-table-gutter: 8px;

.template-variable-manager {
  max-width: $tvmp-panel-max-width;
  margin: 0 auto;
}
.template-variable-manager--header {
  height: $chronograf-page-header-height;
  background: $g0-obsidian;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $tvmp-gutter;

  .page-header__dismiss {
    margin-left: 10px;
    margin-right: -20px;
  }
}
.template-variable-manager--body {
  padding: 18px ($tvmp-gutter - $tvmp-table-gutter) $tvmp-gutter
    ($tvmp-gutter - $tvmp-table-gutter);
  border-radius: 0 0 $radius $radius;
  min-height: $tvmp-min-height;
  max-height: $tvmp-max-height;
  @include gradient-v($g2-kevlar, $g0-obsidian);
  @include custom-scrollbar-round($g0-obsidian, $g3-castle);
}
.template-variable-manager--table,
.template-variable-manager--table-container {
  width: 100%;
}
/* Column Widths */
.tvm--col-1 {
  flex: 0 0 140px;
}
.tvm--col-2 {
  flex: 0 0 140px;
}
.tvm--col-3 {
  flex: 1 0 0;
}
.tvm--col-4 {
  flex: 0 0 30px;

  &.editing {
    flex: 0 0 68px;
  }
}

/* Table Column Labels */
.template-variable-manager--table-heading {
  padding: 0 $tvmp-table-gutter;
  height: 18px;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 12px;
  color: $g11-sidewalk;
  white-space: nowrap;

  > * {
    @include no-user-select();
    padding-left: 6px;
    margin-right: $tvmp-table-gutter;
    &:last-child {
      margin-right: 0;
    }
  }
}

/* Table Body */
.template-variable-manager--table-rows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.template-variable-manager--table-row {
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  padding: $tvmp-table-gutter;
  transition: background-color 0.25s ease;

  &.editing {
    background-color: $g3-castle;
  }

  > * {
    margin-right: $tvmp-table-gutter;
    &:last-child {
      margin-right: 0;
    }
  }
}

.tvm-input,
.form-control.tvm-input-edit {
  font-weight: 600 !important;
  width: 100% !important;
  padding: 0 6px !important;
  font-family: $code-font;
  color: $c-comet !important;
  height: 30px !important;

  &:focus,
  &:focus:hover {
    color: $c-comet !important;
  }
}
.tvm-input {
  font-size: 12px;
  line-height: 26px;
  padding: 0 8px;
  border-radius: $radius;
  border: 2px solid $g5-pepper;
  background-color: $g2-kevlar;
  overflow: hidden;
  transition: border-color 0.25s ease;

  &:hover {
    cursor: text;
    border-color: $g6-smoke;
  }
}
.tvm-values,
.tvm-values-empty {
  width: 100%;
  white-space: pre-wrap;
  overflow: auto;
  font-size: 12px;
  font-weight: 600;
  font-family: $code-font;
  color: $c-pool;
  padding: 0 $tvmp-table-gutter;
  min-height: 30px;
  max-height: 90px;
  line-height: 30px;
  border-radius: $radius;
  background-color: $g5-pepper;
  @include custom-scrollbar-round($g5-pepper, $g7-graphite);
}
.tvm-values-empty {
  color: $g9-mountain;
  font-style: italic;
}
.tvm-csv-instructions {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  font-family: $code-font;
  padding: 0 $tvmp-table-gutter;
  height: 30px;
  line-height: 30px;
  border-radius: $radius;
  background-color: $g5-pepper;
  margin-bottom: 2px;
  color: $g9-mountain;
  font-style: italic;
}

.tvm-query-builder {
  display: flex;
  align-items: center;
  min-height: 30px;
  flex-wrap: wrap;

  > * {
    margin-bottom: 2px;
    margin-right: 2px;
  }
  > *:last-child {
    margin-right: 0;
  }

  .dropdown {
    flex: 1 0 0;

    & > .dropdown-toggle {
      width: 100%;
    }
  }
}
.tvm-query-builder--text {
  @include no-user-select();
  background-color: $g5-pepper;
  border-radius: $radius-small;
  padding: 0 $tvmp-table-gutter;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  color: $c-pool;
  font-size: 12px;
  font-weight: 600;
  font-family: $code-font;
}

.tvm-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > .btn:nth-child(2) {
    margin-left: $tvmp-table-gutter;
  }
}
