/*
    Kapacitor Config Styles
    ----------------------------------------------
*/

$config-endpoint-tab-height: 40px;

$config-endpoint-tab-text: $g10-wolf;
$config-endpoint-tab-text-hover: $g15-platinum;
$config-endpoint-tab-text-active: $g18-cloud;

$config-endpoint-tab-bg: transparent;
$config-endpoint-tab-bg-hover: $g3-castle;
$config-endpoint-tab-bg-active: $g3-castle;

.config-endpoint {
  display: flex;
  align-items: stretch;
}
.config-endpoint--tabs {
  flex: 0 0 0%;
  display: flex;

  .btn-group.tab-group {
    overflow: hidden;
    background-color: $g2-kevlar;
    border-radius: $radius 0 0 $radius;
    margin: 0;
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    align-items: stretch;

    .btn.tab {
      color: $config-endpoint-tab-text;
      background-color: $config-endpoint-tab-bg;
      border-radius: 0;
      height: $config-endpoint-tab-height;
      border: 0;
      padding: 0 50px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      position: relative;

      &:first-child {border-top-left-radius: $radius;}

      &:hover {
        color: $config-endpoint-tab-text-hover;
        background-color: $config-endpoint-tab-bg-hover;
      }
      &.active {
        color: $config-endpoint-tab-text-active;
        background-color: $config-endpoint-tab-bg-active;
      }

      // Checkmark for configured state, hidden by default
      &:after {
        content: "\e918";
        font-family: 'icomoon';
        color: $c-rainforest;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.25s ease;
      }
      &.configured:after {
        opacity: 1;
      }
    }
  }
}
.config-endpoint--tab-contents {
  flex: 1 0 0%;
  background-color: $config-endpoint-tab-bg-active;
  border-radius: 0 $radius $radius 0;
  padding: 16px 42px;
}
