/*
  Function Selector
  ------------------------------------------------------
  Used within the FieldListItem component
*/
$function-selector--bg: $g2-kevlar;
$function-selector--gutter: 6px;
$function-selector--size: 26px;
$function-selector--item: $g3-castle;
$function-selector--text: $g11-sidewalk;
$function-selector--item-hover: $g4-onyx;
$function-selector--text-hover: $g15-platinum;
$function-selector--item-active: $c-pool;
$function-selector--text-active: $g20-white;

.function-selector {
  padding: 4px 11px 8px 32px;
  background-color: $g4-onyx;
}
.function-selector--header {
  background-color: $function-selector--bg;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $function-selector--gutter;
  border-radius: $radius $radius 0 0;
  font-size: 12px;
  font-weight: 600;
  color: $g11-sidewalk;
  @include no-user-select();
}
.function-selector--grid {
  display: flex;
  flex-wrap: wrap;
  padding: $function-selector--gutter;
  border-radius: 0 0 $radius $radius;
  background-color: $function-selector--bg;
}
.function-selector--header + .function-selector--grid {
  padding-top: 0;
}
.function-selector--item {
  @include no-user-select();
  border-radius: $radius;
  font-size: 12px;
  font-weight: 700;
  font-family: $code-font;
  flex: 0 0 calc(25% - 2px);
  margin: 1px;
  text-align: center;
  height: $function-selector--size;
  line-height: ($function-selector--size - 3px);
  background-color: $function-selector--item;
  color: $function-selector--text;
  transition:
    background-color 0.25s ease,
    color 0.25s ease;

  &:hover {
    background-color: $function-selector--item-hover;
    color: $function-selector--text-hover;
    cursor: pointer;
  }
  &.active {
    background-color: $function-selector--item-active;
    color: $function-selector--text-active;
  }
}
