/*
    Styles for Chronograf Users Admin Page
    ----------------------------------------------------------------------------
*/

.chronograf-user--role,
.chronograf-user--org {
  display: inline-block;
  width: 100%;
  height: 22px;
  line-height: 22px;
}
.chronograf-user--role,
.chronograf-user--org,
table.table.chronograf-admin-table .dropdown {
  margin-bottom: 2px;

  &:last-child {
    margin: 0;
  }
}
.chronograf-user--org {
  padding-left: 7px;
}
table.table.chronograf-admin-table thead tr th.align-with-col-text {
  padding-left: 15px;
}
.dropdown-label {
  margin: 0 8px 0 0;
  font-weight: 700;
  color: $g13-mist;
  font-size: 14px;
}
.panel-body.chronograf-admin-table--panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 11px;
}
.chronograf-admin-table--batch {
  border-radius: 5px 5px 0 0;
  background-color: $g4-onyx;
  padding: 11px 38px;
  display: flex;
  align-items: center;
}
.chronograf-admin-table--batch-actions {
  display: flex;
  align-items: center;

  > .dropdown,
  > .btn {
    margin-left: 4px;
  }
}
.chronograf-admin-table--num-selected {
  @include no-user-select();
  margin: 0px 11px 0 0;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: $g13-mist;
}
.super-admin-toggle .dropdown-toggle {
  width: 70px;
}

/* Make dropdowns in admin table appear as plaintext until hover */
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--user td div.dropdown div.btn.dropdown-toggle {
  transition: none;
  background-color: $g3-castle;
  color: $g13-mist;

  > .caret {
    opacity: 0;
  }
}
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--user.selected td div.dropdown div.btn.dropdown-toggle {
  background-color: $g5-pepper;
}
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--user:hover td div.dropdown div.btn.dropdown-toggle {
  background-color: $c-pool;
  color: $g20-white;

  > .caret {
    opacity: 1;
  }

  &:hover {
    background-color: $c-laser;
  }
}
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--user td div.dropdown.open div.btn.dropdown-toggle,
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--user td div.dropdown.open div.btn.dropdown-toggle:hover {
  background-color: $c-hydrogen;
  color: $g20-white;

  > .caret {
    opacity: 1;
  }
}

/* Styles for new user row */
table.table.chronograf-admin-table tbody tr.chronograf-admin-table--new-user {
  background-color: $g4-onyx;
}

/* Highlight "Me" in the users table */
.chronograf-user--me {
  color: $c-rainforest;
  white-space: nowrap;

  > span.icon {
    display: inline-block;
    margin-right: 4px;
  }
}
