$breakpoint-a: 1500px;
$breakpoint-b: 1800px;
$breakpoint-c: 2100px;


@media only screen and (min-width: $breakpoint-a) {
  .data-explorer {
    .query-builder--list-item {
      font-size: 14px;
    }
    .query-builder--heading {
      font-size: 15px;
    }
  }
}

@media only screen and (min-width: $breakpoint-b) {
  .data-explorer {
    .query-builder--list-item {
      font-size: 15px;
    }
    .query-builder--heading {
      font-size: 16px;
    }
    .query-editor--field {
      font-size: 14px;
      line-height: 16px;
    }
  }
}
@media only screen and (min-width: $breakpoint-c) {
  .data-explorer {
    .query-builder--list-item {
      letter-spacing: 0.3px;
    }
    .query-builder--heading {
      font-size: 17px;
      font-weight: 400;
    }
    .query-maker .multi-select-dropdown .dropdown-toggle {
      width: 140px;
    }
    .query-editor--field {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
