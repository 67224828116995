/*
    IFQL Code Mirror Editor
    ----------------------------------------------------------------------------
*/

.time-machine-container {
  display: flex;
  height: 90%;
  justify-content: baseline;
  align-items: stretch;
}

.func-nodes-container,
.time-machine-editor-container {
  flex: 1 0 50%;
}

.time-machine-editor {
  width: 100%;
  height: 100%;
}
