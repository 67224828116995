/*
   Helpers
   -----------------------------------------------------------------------------
   So handy, much dandy
*/

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

//  Flexbox Utilities
//  ----------------------------------------------------------------------------

.u-flex {
	display: flex;
	flex-wrap: wrap;

	&.u-jc-flex-start { justify-content: flex-start; }
	&.u-jc-flex-end { justify-content: flex-end; }
	&.u-jc-center { justify-content: center; }
	&.u-jc-space-between { justify-content: space-between; }
	&.u-jc-space-around { justify-content: space-around; }

	&.u-ai-flex-start { align-items: flex-start; }
	&.u-ai-flex-end { align-items: flex-end; }
	&.u-ai-center { align-items: center; }
	&.u-ai-stretch { align-items: stretch; }
	&.u-ai-baseline { align-items: baseline; }

	&.u-ac-flex-start { align-content: flex-start; }
	&.u-ac-flex-end { align-content: flex-end; }
	&.u-ac-center { align-content: center; }
	&.u-ac-stretch { align-content: stretch; }
	&.u-ac-space-between { align-content: space-between; }
	&.u-ac-space-around { align-content: space-around; }
}

//  Misc. Utilities
//  ----------------------------------------------------------------------------

.margin-zero {
	margin: 0 !important;
}
.margin-bottom-zero {
	margin-bottom: 0 !important;
}
.margin-top-zero {
	margin-top: 0 !important;
}
.margin-left-zero {
	margin-left: 0 !important;
}
.margin-right-zero {
	margin-right: 0 !important;
}

.padding-zero {
	padding: 0 !important;
}
.padding-bottom-zero {
	padding-bottom: 0 !important;
}
.padding-top-zero {
	padding-top: 0 !important;
}
.padding-left-zero {
	padding-left: 0 !important;
}
.padding-right-zero {
	padding-right: 0 !important;
}
