/*
    Styles for InfluxDB Admin Page
    ----------------------------------------------------------------------------
*/

/*
    Admin Table
    ----------------------------------------------------------------------------
*/
.admin-table .dropdown-toggle {
  background-color: transparent;
  font-weight: 600;
  color: $g14-chromium;
  transition: none !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .caret {
    opacity: 0;
  }
}
.admin-table--multi-select-empty .dropdown-toggle {
  color: $g8-storm;
}
.admin-table tbody tr:hover .dropdown-toggle {
  color: $g20-white !important;
  background-color: $c-pool;

  .caret {
    opacity: 1;
  }

  &:hover {
    transition: background-color 0.25s ease;
    background-color: $c-laser;
  }
}
table > tbody > tr > td.admin-table--left-offset,
table > thead > tr > th.admin-table--left-offset {
  padding-left: 15px;
}

table > tbody > tr.admin-table--edit-row,
table > tbody > tr.admin-table--edit-row:hover,
table.table-highlight > tbody > tr.admin-table--edit-row,
table.table-highlight > tbody > tr.admin-table--edit-row:hover {
  background-color: $g5-pepper;
}
.admin-table--change-pw {
  display: flex;
  flex-wrap: nowrap;

  .form-control {
    margin: 0 4px 0 0;
    flex: 1 0 0%;
  }
}
pre.admin-table--query {
  width: 100%;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 0;
}
.admin-table--delete-db {
  display: flex;
  align-items: center;

  > .form-control {
    flex: 1 0 0%;
    margin-right: 4px;
  }
}

/*
    Database Manager
    ----------------------------------------------------------------------------
*/
.db-manager {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
  .db-manager-header--actions {
    visibility: hidden;
  }
  &:hover .db-manager-header--actions {
    visibility: visible;
  }
}
.db-manager-header {
  padding: 0 11px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $g4-onyx;
  border-radius: $radius-small $radius-small 0 0;

  h4 {
    margin: 0;
    color: $c-potassium;
    font-size: 16px;
    font-family: $code-font;
    padding-left: 6px;
    @include no-user-select();
  }
}
.db-manager-header--actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.db-manager-header--edit {
  justify-content: flex-start;

  .form-control {
    margin: 0 8px 0 0;
    flex: 1 0 0%;
  }
}

.db-manager-table {
  background-color: $g4-onyx;
  padding: 9px 11px;
  border-radius: 0 0 $radius-small $radius-small;

  .table-highlight > tbody > tr:hover {
    background-color: $g5-pepper;
  }
}

/*
    Chronograf Admin
    ----------------------------------------------------------------------------
*/
.all-users-admin-toggle {
  display: flex;
  align-items: center;
  margin-right: 30px;

  span {
    display: inline-block;
    margin-left: 8px;
    font-weight: 500;
    font-style: italic;
    color: $g10-wolf;
    transition: color 0.25s ease;
    @include no-user-select();
  }
  .slide-toggle.active + span {
    font-style: normal;
    color: $g15-platinum;
  }
}
