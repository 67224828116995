/*
   Chronograf Styles
   -----------------------------------------------------------------------------
*/

// Modules
@import 'modules/influx-colors';
@import 'modules/variables';
@import 'modules/mixins';
@import 'modules/helpers';

// Fonts
@import 'fonts/fonts';
@import 'fonts/icon-font';

// Theme
@import 'theme/chronograf-theme';

// Vendor
@import 'external/react-grid-layout';
@import 'external/fixed-data-table-base';
@import 'external/fixed-data-table-style';
@import 'external/fixed-data-table';
@import 'external/codemirror';
@import '../../node_modules/codemirror/theme/material.css';

// Layout
@import 'layout/page';
@import 'layout/page-header';
@import 'layout/page-subsections';
@import 'layout/sidebar';
@import 'layout/overlay-technology';

// Components
@import 'components/annotations';
@import 'components/crosshairs';
@import 'components/ceo-display-options';
@import 'components/confirm-button';
@import 'components/confirm-or-cancel';
@import 'components/code-mirror-theme';
@import 'components/color-dropdown';
@import 'components/custom-time-range';
@import 'components/customize-fields';
@import 'components/dygraphs';
@import 'components/fancy-scrollbars';
@import 'components/fancy-table';
@import 'components/fill-query';
@import 'components/flip-toggle';
@import 'components/function-selector';
@import 'components/graph-tips';
@import 'components/graph';
@import 'components/input-click-to-edit';
@import 'components/input-tag-list';
@import 'components/newsfeed';
@import 'components/opt-in';
@import 'components/organizations-table';
@import 'components/page-header-dropdown';
@import 'components/page-header-editable';
@import 'components/page-spinner';
@import 'components/static-legend';
@import 'components/query-maker';
@import 'components/react-tooltips';
@import 'components/redacted-input';
@import 'components/resizer';
@import 'components/search-widget';
@import 'components/slide-toggle';
@import 'components/info-indicators';
@import 'components/source-selector';
@import 'components/tables';
@import 'components/table-graph';
@import 'components/threesizer';
@import 'components/threshold-controls';
@import 'components/kapacitor-logs-table';

// Pages
@import 'pages/config-endpoints';
@import 'pages/signup';
@import 'pages/auth-page';
@import 'pages/kapacitor';
@import 'pages/dashboards';
@import 'pages/admin';
@import 'pages/users';
@import 'pages/tickscript-editor';
@import 'pages/time-machine';
@import 'pages/manage-providers';

// TODO
@import 'unsorted';
