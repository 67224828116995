/*
  Styles for the Write Data overlay
  ------------------------------------------------------
  Accessed via Data Explorer
*/

$write-data--form-z: 2;
$write-data--drag-overlay-z: 1;
$write-data--max-width: 960px;
$write-data--gutter: 30px;
$write-data--margin: 18px;
$write-data--input-height: calc(90vh - 48px - 60px - 36px); // Heights of everything but input height
$write-data--transition: opacity 0.4s ease;

.write-data-form {
  max-width: $write-data--max-width;
  margin: 0 auto;
  position: relative;
  z-index: $write-data--form-z;
}
.write-data-form--header {
  height: $chronograf-page-header-height;
  background: $g0-obsidian;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $write-data--gutter;

  .page-header__dismiss {
    margin-left: 10px;
    margin-right: -10px;
  }
  .dropdown {
    margin-left: 8px;
    & > .dropdown-toggle {
      width: 180px;
    }
  }
}
.write-data-form--body {
  padding: $write-data--margin $write-data--gutter;
  border-radius: 0 0 $radius $radius;
  @include gradient-v($g2-kevlar,$g0-obsidian);
}
textarea.form-control.write-data-form--input {
  height: $write-data--input-height;
  padding: $write-data--margin;
  max-width: 100%;
  min-width: 100%;
  font-family: $code-font;
  resize: none;
}
.write-data-form--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $write-data--margin;
}
.write-data-form--helper {
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  color: $g9-mountain;
  padding-left: $write-data--margin + 2px;
}
.write-data-form--submit {
  width: 140px;
}
.write-data-form--file {
  height: $write-data--input-height;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $g2-kevlar;
  border: 2px solid $g4-onyx;
  margin-bottom: 48px;
  border-radius: 3px;
  transition:
    background-color 0.25s ease,
    border-color 0.25s ease;

  > p {
    color: $g12-forge;
    font-weight: 600;
    margin: 20px 0;
  }
}
.write-data-form--graphic {
  background-image: url(assets/images/drag-drop-icon.svg);
  background-size: 100% 100%;
  background-position: center center;
  width: 90px;
  height: 90px;
  margin-bottom: 68px;

  &.write-data-form--graphic_success {
    background-image: url(assets/images/drag-drop-icon--success.svg);
    margin-bottom: 0;
  }
}

input[type="file"].write-data-form--upload {
  display: none;
}
.write-data-form--filepath_selected,
.write-data-form--filepath_empty {
  @include no-user-select();
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 30px 0;
}
.write-data-form--filepath_empty {color: $g12-forge;}
.write-data-form--filepath_selected {color: $c-rainforest;}

.write-data-form--file-submit {
  margin-top: 30px;
  & .btn {
    width: 124px;
    margin: 0 8px;
  }
}

/*
  Styles for hover state and drag-over state look the same
  ------------------------------------------------------------------------------
*/
.write-data-form--file.write-data-form--file_active:hover,
.overlay-technology.drag-over .write-data-form--file {
  cursor: pointer;
  background-color: $g4-onyx;
  border-color: $g6-smoke;
}
