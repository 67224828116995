/*
   Code Styles
   -----------------------------------------------------------------------------
*/


$code-bg-color: $g2-kevlar;
$code-scrollbar: $c-pool;
$code-font-color: $c-pool;
$code-font-size: 11px;
$code-font-weight: 500;

code {
  font-weight: $code-font-weight;
  background-color: $code-bg-color;
  border: 0;
  color: $code-font-color;
  font-family: $code-font;
  font-size: $code-font-size;
  line-height: $code-font-size;
  letter-spacing: 0.02em;
  border-radius: 2px;
  padding: 2.5px 5px;
  margin: 0 1px 0 2px;

  /* Reset default tag styles */
  b,strong,i,em,mark,small,del,ins,sub,sup,u {
    font-size: $code-font-size;
    font-weight: $code-font-weight;
    text-decoration: none;
    font-style: normal;
    background-color: inherit;
    color: $code-font-color;
    position: static;
    top: 0;
    bottom: 0;
    padding: 0;
  }

  sup, sub {
    position: relative;
    font-size: 85%;
    margin-left: 2px;
    color: inherit;
  }
  sup {
    top: -4px;
  }
  sub {
    top: 4px;
  }

  b { color: $c-dreamsicle;}
  i { color: $c-pool;}
  u { color: $c-rainforest;}

  &.rainbow-highlighter {
    text-shadow: none;
    background-image: none;
  }
}
pre {
  font-size: $code-font-size;
  line-height: 17px;
  background-color: $code-bg-color;
  border: 0;
  color: $code-font-color;
  white-space: normal;
  border-radius: $ix-radius;
  position: relative;
  font-family: $code-font;

  code {
    margin: 0;
    border: none;
    background-color: transparent;
    background-image: none;
    white-space: pre-wrap;
    word-break: normal;
    word-wrap: normal;
    border-radius: 0;
  }
  @include custom-scrollbar($code-bg-color,$code-scrollbar);

  &.rainbow-highlighter {
    background-image: none;
    @include custom-scrollbar($g2-kevlar,$c-pool);

    code {
      text-shadow: none;
    }
  }
}

/* Code Highlighting */
$code-highlight-a: $g10-wolf;
$code-highlight-b: $c-hydrogen;
$code-highlight-c: $c-pool;
$code-highlight-d: $c-viridian;
$code-highlight-e: $c-honeydew;
$code-highlight-f: $g20-white;
$code-highlight-g: $c-curacao;
$code-highlight-h: $c-tungsten;
$code-highlight-i: $c-honeydew;
$code-highlight-j: $c-comet;
$code-highlight-k: $g6-smoke;

code, pre {
  &.rainbow-highlighter {
    color: $c-potassium;
    border-color: $c-shadow;



    b { color: $code-highlight-a; }
    strong { color: $code-highlight-b; }
    i { color: $code-highlight-c; }
    em { color: $code-highlight-d; }
    mark { color: $code-highlight-e; }
    small { color: $code-highlight-f; }
    del { color: $code-highlight-g; }
    ins { color: $code-highlight-h; }
    sub { color: $code-highlight-i; }
    sup { color: $code-highlight-j; }

    /* Literal highlighting */
    u {
      color: $c-potassium;
      background-color: $code-highlight-k;
      padding: 2px 4px;
      text-indent: -4px;
    }

    sup,sub {
      position: initial;
      top: 0;
      margin: 0;
      font-size: 100%;
    }
  }
}

/* Language Labels */
pre:before {
  display: inline-block;
  width: 100%;
  color: $g7-graphite;
  font-weight: 700;
  margin-bottom: $ix-marg-a;
  text-transform: uppercase;
}
pre[data-lang="html"]:before {
  content: 'HTML';
}
pre[data-lang="css"]:before {
  content: 'CSS';
}
pre[data-lang="js"]:before {
  content: 'Javascript';
}
