/*
    Graph Tips component styles
    ----------------------------------------------------------------
*/
.graph-tips {
  @include no-user-select();
  display: inline-block;
  padding: 0 4px;
  border: 0;
  height: 30px;
  line-height: 30px;

  > span {
    display: inline-block;
    text-align: center;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: $g13-mist;
    color: $g0-obsidian;
    line-height: 18px;
    font-weight: 900;
    cursor: default;
  }
}