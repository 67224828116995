#signup-page {
  padding: 100px 0;

  // Progress indicator for admin signup
  .signup-progress-circle {
    margin-top: 20px;
    display: inline-block;
    width: 90px;
    height: 90px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 90px;
    text-align: center;
    font-weight: 300;
    font-size: 22px;

    &.step1of3 {
      background-image: url(/assets/images/signup-progress-circle-1.svg);
    }
    &.step2of3 {
      background-image: url(/assets/images/signup-progress-circle-2.svg);
    }
    &.step3of3 {
      background-image: url(/assets/images/signup-progress-circle-3.svg);
    }
    &.step1of2 {
      background-image: url(/assets/images/signup-progress-circle-1of2.svg);
    }
    &.step2of2 {
      background-image: url(/assets/images/signup-progress-circle-2of2.svg);
    }
  }

  // Custom Styles for "Next" buttons in signup flow
  .btn.btn-next {
    margin-top: 40px;
    margin-bottom: 10px;

    > span {
      font-weight: 400;
    }
  }
  
  // Making the one panel in signup flow more spacious
  .panel-body {
    padding: 40px;
  }

  // Custom single-line form for inviting users
  .user-invite-form {
    display: flex;
    align-items: center;

    input[type="email"] {
      flex: 6;
      margin-left: 5px;
    }
    select {
      flex: 3;
    }
    .btn {
      flex: 1;
      margin-left: 5px;
    }
  }
  .role-description {
    color: $g10-wolf;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  // Invited Users Table
  table.table.invited-users {
    tbody tr td,
    thead tr th {

      text-align: right;

      &:first-child {
        text-align: left;
        font-weight: 600;
      }
    }

    tbody tr:last-child td {
      border-bottom: 2px solid $g18-cloud;
    }
  }
  // Custom style for uninviting users
  a.uninvite-user {
    color: $g13-mist;

    &:hover {
      color: $c-curacao;
    }
  }

  .database-options-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  #server {
    margin-bottom: -2px;
  }

  .server-group {
    .form-group {
      margin-bottom: 1px;
    }
    display: flex;
    align-items: center;

    .btn {
      margin-left: 10px;
      align-self: flex-end;
    }
  }

  .confirmation-group {
    margin-top: 20px;
  }

  .invite-user-group {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .form-group.invite-user-email {
      flex: none;
      margin-bottom: 0;
      width: 60%;
    }

    .btn-invite {
      width: 15%;
    }

  }


  .signup-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
    border-radius: 50px;
    border: none;
    font-size: large;
    margin-top: 20px;
  }

  .global-admin,
  .setup-database,
  .create-admin,
  .create-login,
  .access-info,
  .create-cluster {
    margin-top: 20px;
    padding: 0 60px;
    border: 1px solid #EEF0F2;
    display: flex;
    flex-direction: column;

    .password-group {
      display: flex;
    }

    .table-container {
      max-height: 170px;
      overflow-y: auto;
    }

    .server-table {
      background-color: white;
      margin-bottom: 0;
    }

    .confirmation-group {
      display: flex;
      justify-content: center;

      .btn {
        width: 200px;
        margin-right: 10px;
      }
    }

    .form-group {
      flex: 1;
    }

    h1 {
      text-align: center;
    }

    .copy {
      align-self: center;
      text-align: center;
      background-color: white;
      width: 100%;
    }

    .label.label-success,
    .label.label-danger {
      margin-right: 5px;
    }
  }
}
