/*
    Unsorted
    ----------------------------------------------
*/
.text-right .btn {
  margin: 0 0 0 4px;
}
.text-center .btn {
  margin: 0 6px;
}
.default-source-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  background-color: $g2-kevlar;
  color: $c-pool;
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: 4px;
}
.progress-label {
  margin-bottom: 4px;
  color: $g11-sidewalk;
  width: 100%;

  strong {
    font-weight: 500;
    color: $g8-storm;
  }
}
.modal {
  form {
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
  .form-grid {
    width: 100%;
    display: inline-block;

    &.padding-top {
      padding-top: 15px;
    }
  }
}

/*
    Generic Empty State
    ----------------------------------------------
*/
.generic-empty-state {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: $g12-forge;
  padding: 20px 0;
  @include no-user-select();

  h4,
  h5 {
    font-weight: 400;
  }

  .icon {
    margin-bottom: 11px;
  }
}

/*
    Loading Dots
    ----------------------------------------------
*/
.loading-dots {
  position: absolute;
  transform: translate(0, 0);
  transform: translateX(50%);
  width: 16px;
  height: 18px;

  div {
    width: 4px;
    height: 4px;
    background-color: $g6-smoke;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  div:nth-child(1) {
    left: 0;
    animation: refreshingSpinnerA 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
  div:nth-child(2) {
    left: 50%;
    animation: refreshingSpinnerB 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
  div:nth-child(3) {
    left: 100%;
    animation: refreshingSpinnerC 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
}

/*
    Custom Tabs
    ----------------------------------------------
*/
.tab-group {
  display: flex;
  justify-content: flex-start;
  display: inline-block;

  .btn.tab {
    border: 2px solid $g17-whisper;
    border-right-width: 0;
    padding: 10px 20px;
    margin: 0 !important;
    font-weight: 400;
    color: $g11-sidewalk;
    background-color: $g17-whisper;
    box-shadow: none;
    transition: color 0.25s ease, background-color 0.25s ease;

    &:hover {
      cursor: pointer;
      background-color: $g18-cloud;
      color: $g9-mountain;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
      border-right-width: 2px;
    }
    &.active {
      background-color: white;
      color: $g8-storm;
    }
  }
}
.currently-connected-source {
  color: $c-rainforest;
  font-weight: 600;
  font-size: 12px;
  margin: 0 4px;
  @include no-user-select();
}

br {
  @include no-user-select();
}

.select-source-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  @include custom-scrollbar($g2-kevlar, $c-pool);
  @include gradient-v($g2-kevlar, $g0-obsidian);
}

.source-table--connect {
  width: 74px;
}
.source-table--connect-col {
  width: 90px;
}
.source-table--kapacitor {
  border-left: 2px solid $g5-pepper;
  width: 278px;
}
.source-table--kapacitor__view-only {
  @include no-user-select();
  font-size: 14px;
  font-weight: 600;
}

/*
  Styles for the Status Dashboard
  -----------------------------------------------------------------------------
  Not enough of these to merit their own page, will organize later
*/
.alerts-widget,
.getting-started {
  padding: 0 16px;
}
.getting-started--cell {
  color: $g11-sidewalk;
  background-color: $g2-kevlar;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 4px;

  &.intro {
    @include gradient-h($c-pool, $c-star);
    color: $g20-white;
  }

  p {
    font-size: 13px;
  }
}
.alerts-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > .btn {
    margin: 20px 0;
  }

  .alert-history-table {
    flex: 1 0 0;
  }
}

/*
  Version Number
  -----------------------------------------------------------------------------
*/
.version-number {
  text-align: center;
  margin: 20px 0;
  font-family: $code-font;
  color: $g11-sidewalk;
  font-size: 13px;
}

/*
  View TICKscript Overlay
  -----------------------------------------------------------------------------
*/
$tick-script-overlay-margin: 30px;
.tick-script-overlay {
  max-width: 960px;
  margin: 0 auto $tick-script-overlay-margin auto;
  height: calc(100% - #{$tick-script-overlay-margin});
  position: relative;

  .write-data-form--body {
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
  }
}
.tick-script-overlay--sample {
  margin: 0;
  white-space: pre-wrap;
  font-size: 14px;
  padding: 20px;
  border: 2px solid $g4-onyx;
}

/*
  GROUP BY TIME Dropdown
  -----------------------------------------------------------------------------
*/
.group-by-time {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.group-by-time--label {
  white-space: nowrap;
  margin: 0;
  color: $g12-forge;
  padding: 0 6px;
  height: 30px;
  line-height: 30px;
}
.group-by-time--dropdown .dropdown-toggle {
  width: 70px;
}
.group-by-time--dropdown .dropdown-selected,
.group-by-time--dropdown .dropdown-item {
  font-family: $code-font;
}

/*
  Fix Display issue in Safari
  -----------------------------------------------------------------------------
  The pseudo elements offset items in the tablists
*/
.nav-tablist {
  &:before,
  &:after {
    content: none;
    display: none;
  }
}

/*
  Data Explorer download CSV button
  -----------------------------------------------------------------------------
*/
.icon.download.dlcsv:before {
  content: '\e91d';
  font-weight: bold;
  color: #bec2cc;
}
.btn.btn-sm.btn-default.dlcsv {
  margin-left: 10px;
}

/*
  Dropdown clickable area fix
  -----------------------------------------------------------------------------
  TODO: Move this into theme, not sure how this broke
*/

.dropdown .dropdown-menu li.dropdown-item {
  display: block;

  > a {
    display: block;
  }
}

/*  Deletable tabs in .nav-tablist
  -----------------------------------------------------------------------------
  TODO: Add this into the theme styles
*/
.nav-tab--delete {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
  border-radius: 3px;
  position: relative;
  background-color: $g5-pepper;

  // Psuedo elements used to form the X
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $g11-sidewalk;
    width: 10px;
    height: 2px;
    border-radius: 1px;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/*
  Auto Refresh Dropdown
  -----------------------------------------------------------------------------
*/
.autorefresh-dropdown {
  display: flex;
  flex-wrap: nowrap;

  &.paused .dropdown {
    margin-right: 4px;
  }
  &.paused .dropdown > .btn.dropdown-toggle {
    width: 126px;
  }
}

/*
  Dashboard Switcher
  -----------------------------------------------------------------------------
*/
.dropdown.dashboard-switcher {
  margin-right: 4px;
}
.dropdown.dashboard-switcher .btn.dropdown-toggle {
  justify-content: center;
}
.dropdown.dashboard-switcher + h1.page-header__title {
  margin-left: 6px;
}

.dropdown.dashboard-switcher .dropdown-item {
  white-space: nowrap;
}

/*
  Dashboard Name Editing
  -----------------------------------------------------------------------------
*/
$dash-editable-header-padding: 7px;

.page-header__left.page-header__dash-editable,
.dashboard-title,
.dashboard-title input[type='text'].form-control.dashboard-title--input,
.dashboard-title h1 {
  flex: 1 0 0;
}
.dashboard-title {
  display: flex;

  input[type='text'].form-control.dashboard-title--input,
  input[type='text'].form-control.dashboard-title--input:focus,
  h1 {
    font-size: $page-header-size;
    font-weight: $page-header-weight;
    padding: 0 $dash-editable-header-padding;
  }

  input[type='text'].form-control.dashboard-title--input,
  input[type='text'].form-control.dashboard-title--input:focus {
    font-size: $page-header-size;
    font-weight: $page-header-weight;
  }

  h1 {
    @include no-user-select();
    position: relative;
    border: 2px solid $g0-obsidian;
    color: $g17-whisper;
    height: 30px;
    line-height: 28px;
    border-radius: 4px;
    margin: 0;
    letter-spacing: 0;
    text-transform: none;
    transition: color 0.25s ease, background-color 0.25s ease,
      border-color 0.25s ease;

    &:after {
      content: '\f058';
      font-family: 'icomoon';
      position: absolute;
      font-size: 15px;
      top: 50%;
      right: $dash-editable-header-padding;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.25s ease;
      color: $g11-sidewalk;
    }

    &:hover {
      cursor: text;
      color: $g20-white;
      background-color: $g3-castle;
      border-color: $g3-castle;

      &:after {
        opacity: 1;
      }
    }
  }
}

/*  Add borders between items in .nav-tablist
    -----------------------------------------------------------------------------
    TODO: Add these styles into the theme
  */

.nav.nav-tablist {
  background-color: $g5-pepper;

  > li {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/*
  Fake form padding without <form>

*/

div.faux-form {
  .form-group.col-xs-1,
  .form-group.col-xs-2,
  .form-group.col-xs-3,
  .form-group.col-xs-4,
  .form-group.col-xs-5,
  .form-group.col-xs-6,
  .form-group.col-xs-7,
  .form-group.col-xs-8,
  .form-group.col-xs-9,
  .form-group.col-xs-10,
  .form-group.col-xs-11,
  .form-group.col-xs-12,
  .form-group.col-sm-1,
  .form-group.col-sm-2,
  .form-group.col-sm-3,
  .form-group.col-sm-4,
  .form-group.col-sm-5,
  .form-group.col-sm-6,
  .form-group.col-sm-7,
  .form-group.col-sm-8,
  .form-group.col-sm-9,
  .form-group.col-sm-10,
  .form-group.col-sm-11,
  .form-group.col-sm-12,
  .form-group.col-md-1,
  .form-group.col-md-2,
  .form-group.col-md-3,
  .form-group.col-md-4,
  .form-group.col-md-5,
  .form-group.col-md-6,
  .form-group.col-md-7,
  .form-group.col-md-8,
  .form-group.col-md-9,
  .form-group.col-md-10,
  .form-group.col-md-11,
  .form-group.col-md-12,
  .form-group.col-lg-1,
  .form-group.col-lg-2,
  .form-group.col-lg-3,
  .form-group.col-lg-4,
  .form-group.col-lg-5,
  .form-group.col-lg-6,
  .form-group.col-lg-7,
  .form-group.col-lg-8,
  .form-group.col-lg-9,
  .form-group.col-lg-10,
  .form-group.col-lg-11,
  .form-group.col-lg-12 {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/*
  Stretch to fit Dropdowns
  -----------------------------------------------------------------------------
*/

div.dropdown.dropdown-stretch,
div.dropdown.dropdown-stretch > div.dropdown-toggle,
div.dropdown.dropdown-stretch > button.dropdown-toggle {
  width: 100%;
}

/*
  Delete Source
  -----------------------------------------------------------------------------
*/
.delete-source {
  display: inline-block;
}

/*
  Add spacing between time format dropdown and input
  -----------------------------------------------------------------------------
*/
.custom-time-format {
  margin-top: 4px;
}
