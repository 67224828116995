/*
  Setting lowermost styles here
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  background-color: $g0-obsidian;
}
body {
  display: flex;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: stretch;
  overflow: hidden;

  > #react-root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .container {
      margin-top: 60px;
    }
  }
}
