/*
    Customize Fields
    ----------------------------------------------------------------------------
    Primarily used within Table Options inside Cell Editor Overlay
*/

.customizable-field {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  margin-top: 8px;

  &:first-of-type {
    margin-top: 0;
  }

  &.dragging {
    border-radius: 4px;
    opacity: 0.25;
    @include gradient-h($c-pool, $c-star);

    > * {
      opacity: 0;
    }
  }
}

.customizable-field--label,
.customizable-field--label__hidden {
  @include no-user-select();
  height: 30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 11px 0 0;
  border-radius: 4px;
  width: 60%;
  transition: background-color 0.25s ease, color 0.25s ease;

  > span {
    margin-right: 5px;
  }
}

.customizable-field--label {
  color: $g14-chromium;
  background-color: $g5-pepper;
}

.customizable-field--label__hidden {
  background-color: $g4-onyx;
  color: $g7-graphite;
  font-style: italic;
}

.customizable-field--input {
  margin-left: 4px;
  width: calc(40% - 4px);
  display: flex;
  align-items: center;
  height: 100%;
}
.customizable-field .input-cte__empty {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.customizable-field--name {
  flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 13px;
  margin-left: 6px;
}
.customizable-field--drag,
.customizable-field--visibility {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}
.customizable-field--visibility {
  width: 18px;
}
.customizable-field--drag .hamburger,
.customizable-field--visibility .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customizable-field--drag .hamburger {
  width: 10px;
  height: 2px;
  background-color: $g11-sidewalk;
  transition: background-color 0.25s ease;
  border-radius: 1px;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color: $g11-sidewalk;
    transition: background-color 0.25s ease;
    border-radius: 1px;
    left: 0;
  }
  &:before {
    top: -4px;
  }
  &:after {
    top: 4px;
  }
}

.customizable-field--label__hidden .customizable-field--drag .hamburger {
  &,
  &:before,
  &:after {
    background-color: $g7-graphite;
  }
}
