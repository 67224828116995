/*
    IFQL Schema Explorer -- Tree View
    ----------------------------------------------------------------------------
*/

$ifql-tree-indent: 26px;
$ifql-tree-line: 2px;

.ifql-schema-explorer {
  width: 100%;
  height: 100%;
  background-color: $g2-kevlar;
  min-width: 200px;
}

.ifql-schema-tree {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0;

  > .ifql-schema-tree {
    padding-left: $ifql-tree-indent;
  }
}

.ifql-schema-tree__empty {
  height: $ifql-tree-indent;
  display: flex;
  align-items: center;
  padding: 0 11px;
  font-size: 12px;
  font-weight: 600;
  color: $g8-storm;
  font-style: italic;
}

.ifql-schema-item-toggle {
  width: $ifql-tree-indent;
  height: $ifql-tree-indent;
  position: relative;

  // Plus Sign
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $g11-sidewalk;
    width: $ifql-tree-indent / 3;
    height: $ifql-tree-line;
    transition: transform 0.25s ease, background-color 0.25s ease;
  }
  // Vertical Line
  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.ifql-schema-item {
  @include no-user-select();
  position: relative;
  height: $ifql-tree-indent;
  display: flex;
  align-items: center;
  padding: 0 11px;
  padding-left: 0;
  font-size: 12px;
  font-weight: 600;
  color: $g11-sidewalk;
  white-space: nowrap;
  transition: color 0.25s ease, background-color 0.25s ease;

  > span.icon {
    position: absolute;
    top: 50%;
    left: $ifql-tree-indent / 2;
    transform: translate(-50%, -50%);
  }

  &:hover {
    color: $g17-whisper;
    cursor: pointer;
    background-color: $g4-onyx;

    .ifql-schema-item-toggle:before,
    .ifql-schema-item-toggle:after {
      background-color: $g17-whisper;
    }
  }

  .expanded > & {
    color: $c-pool;

    .ifql-schema-item-toggle:before,
    .ifql-schema-item-toggle:after {
      background-color: $c-pool;
    }
    .ifql-schema-item-toggle:before {
      transform: translate(-50%, -50%) rotate(-90deg);
      width: $ifql-tree-line;
    }
    .ifql-schema-item-toggle:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    &:hover {
      color: $c-laser;

      .ifql-schema-item-toggle:before,
      .ifql-schema-item-toggle:after {
        background-color: $c-laser;
      }
    }
  }

  &.readonly,
  &.readonly:hover {
    padding-left: $ifql-tree-indent + 8px;
    background-color: transparent;
    color: $g11-sidewalk;
    cursor: default;
  }
}

/* Tree Node Lines */
.ifql-tree-node:before,
.ifql-tree-node:after {
  content: '';
  background-color: $g4-onyx;
  position: absolute;
}

// Vertical Line
.ifql-tree-node:before {
  top: 0;
  left: $ifql-tree-indent / 2;
  width: $ifql-tree-line;
  height: 100%;
}
.ifql-tree-node:last-child:before {
  height: $ifql-tree-indent / 2;
}

// Horizontal Line
.ifql-tree-node:after {
  top: $ifql-tree-indent / 2;
  left: $ifql-tree-indent / 2;
  width: $ifql-tree-indent / 2;
  height: $ifql-tree-line;
}

/*
    Controls
    ----------------------------------------------------------------------------
*/
.ifql-schema--controls {
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ifql-schema--filter {
  flex: 1 0 0;
  margin-right: 4px;
}



// Hints
.ifql-schema-type {
  color: $g11-sidewalk;
  display: inline-block;
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;

  .ifql-schema-item:hover  & {
    opacity: 1;
  }
}
