/*

    Name:       CHRONOGRAF YO
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original material color scheme by Mattia Astorino (https://github.com/equinusocio/material-theme)

*/

.react-codemirror2 {
  position: relative;
  width: 100%;
  height: 100%;
}
.cm-s-material.CodeMirror {
  border-radius: 0 0 $radius $radius;
  font-family: $code-font;
  background-color: transparent;
  color: $g13-mist;
  font-weight: 600;
  height: 100%;
}

.CodeMirror-vscrollbar {
  @include custom-scrollbar-round($g2-kevlar, $g6-smoke);
}

.CodeMirror-hscrollbar {
  @include custom-scrollbar-round($g0-obsidian, $g6-smoke);
}

.cm-s-material .CodeMirror-gutters {
  @include gradient-v($g2-kevlar, $g0-obsidian) border: none;
}

.cm-s-material .CodeMirror-gutters .CodeMirror-gutter {
  background-color: fade-out($g4-onyx, 0.75);
  height: calc(100% + 30px);
}

.CodeMirror-gutter.CodeMirror-linenumbers {
  width: 60px;
}

.cm-s-material.CodeMirror .CodeMirror-sizer {
  margin-left: 60px;
}

.cm-s-material.CodeMirror .CodeMirror-linenumber.CodeMirror-gutter-elt {
  padding-right: 9px;
  width: 46px;
  color: $g8-storm;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: rgb(83, 127, 126);
}

.cm-s-material .CodeMirror-cursor {
  width: 2px;
  border: 0;
  background-color: $g20-white;
  box-shadow: 0 0 3px $c-laser, 0 0 6px $c-ocean, 0 0 11px $c-amethyst;
}

.cm-s-material div.CodeMirror-selected,
.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background-color: fade-out($g8-storm, 0.7);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line>span::selection,
.cm-s-material .CodeMirror-line>span>span::selection {
  background: rgba(255, 255, 255, 0.10);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line>span::-moz-selection,
.cm-s-material .CodeMirror-line>span>span::-moz-selection {
  background: rgba(255, 255, 255, 0.10);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0);
}

.cm-s-material .cm-keyword {
  color: $c-comet;
}

.cm-s-material .cm-operator {
  color: $c-dreamsicle;
}

.cm-s-material .cm-variable-2 {
  color: #80CBC4;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: $c-laser;
}

.cm-s-material .cm-builtin {
  color: #DECB6B;
}

.cm-s-material .cm-atom {
  color: $c-viridian;
}

.cm-s-material .cm-number {
  color: $c-daisy;
}

.cm-s-material .cm-def {
  color: rgba(233, 237, 237, 1);
}

.cm-s-material .cm-string {
  color: $c-krypton;
}

.cm-s-material .cm-string-2 {
  color: #80CBC4;
}

.cm-s-material .cm-comment {
  color: $g10-wolf;
}

.cm-s-material .cm-variable {
  color: $c-laser;
}

.cm-s-material .cm-tag {
  color: #80CBC4;
}

.cm-s-material .cm-meta {
  color: #80CBC4;
}

.cm-s-material .cm-attribute {
  color: #FFCB6B;
}

.cm-s-material .cm-property {
  color: #80CBAE;
}

.cm-s-material .cm-qualifier {
  color: #DECB6B;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #DECB6B;
}

.cm-s-material .cm-tag {
  color: rgba(255, 83, 112, 1);
}

.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #EC5F67;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

// CodeMirror hints
.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
  -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, .2);
  border-radius: 3px;
  border: 1px solid silver;
  background: white;
  font-size: 90%;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}