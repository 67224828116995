/*
   Alerts
   -----------------------------------------------------------------------------
*/

.alert {
  border-style: solid;
  border-width: 0;
  border-radius: $ix-radius;
  padding: 16px 16px 16px 44px;
  @extend %no-user-select;
  position: relative;
  margin-bottom: 12px;

  > span.icon {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
    font-size: $ix-text-base-2;
  }
}

.alert-message {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

//  Mixin for Alert Themes
//  ----------------------------------------------------------------------------
@mixin alert-styles(
  $bg-color,
  $bg-color-2,
  $text-color,
  $link-color,
  $link-hover
) {
  font-size: 16px;

  @include gradient-h($bg-color, $bg-color-2);
  color: $text-color;

  a:link,
  a:visited {
    color: $link-color;
    font-weight: 700;
    text-decoration: underline;
    transition: color 0.25s ease;
  }
  a:hover {
    color: $link-hover;
    border-color: $link-hover;
  }
  span.icon {
    color: $text-color;
  }
  .alert-close:before,
  .alert-close:after {
    background-color: $text-color;
  }
}

//  Alert Themes
//  ----------------------------------------------------------------------------
.alert-success {
  @include alert-styles(
    $c-rainforest,
    $c-pool,
    $g20-white,
    $c-wasabi,
    $g20-white
  );
}
.alert-primary {
  @include alert-styles($c-pool, $c-ocean, $g20-white, $c-neutrino, $g20-white);
}
.alert-warning {
  @include alert-styles($c-star, $c-pool, $g20-white, $c-neutrino, $g20-white);
}
.alert-error {
  @include alert-styles(
    $c-curacao,
    $c-star,
    $g20-white,
    $c-marmelade,
    $g20-white
  );
}
.alert-info {
  @include alert-styles(
    $g20-white,
    $g16-pearl,
    $g8-storm,
    $ix-link-default,
    $ix-link-default-hover
  );
}
.alert-dark {
  @include alert-styles(
    $c-sapphire,
    $c-shadow,
    $c-moonstone,
    $ix-link-default,
    $ix-link-default-hover
  );
}
