// Overrides default Dygraphs styles

.dygraph {
  &:hover {
    cursor: default;
  }
}

.graph-vertical-marker {
  top: 0;
  bottom: 0;
  width: 2px;
  transform: translateX(16px - 1px);
  position: absolute;
  pointer-events: none;
  display: none;
  background: fade-out($g20-white, 1);
  background: -moz-linear-gradient(
    top,
    fade-out($g20-white, 1) 0%,
    fade-out($g20-white, 0.71) 6%,
    fade-out($g20-white, 0.71) 80%,
    fade-out($g20-white, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    fade-out($g20-white, 1) 0%,
    fade-out($g20-white, 0.71) 6%,
    fade-out($g20-white, 0.71) 80%,
    fade-out($g20-white, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    fade-out($g20-white, 1) 0%,
    fade-out($g20-white, 0.71) 6%,
    fade-out($g20-white, 0.71) 80%,
    fade-out($g20-white, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='fade-out($g20-white, 0.71)', endColorstr='fade-out($g20-white, 0.71)',GradientType=0 );
}

/* Axis Labels */
.dygraph-axis-label {
  color: $g11-sidewalk !important;
  font-weight: 600 !important;
  user-select: none;
  font-size: 11px;
}
.dygraph-axis-label-y {
  padding: 0 9px 0 0 !important;
  text-align: left !important;
  left: 0 !important;
  user-select: none;
}
.dygraph-axis-label-y2 {
  padding: 0 0 0 9px !important;
  text-align: right !important;
  user-select: none;
}
.graph-container > div > div > div > div {
}

/* Vertical Axis Labels */
.dygraph-ylabel,
.dygraph-y2label {
  user-select: none;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 11px;
  top: -12px !important;
  color: $g11-sidewalk;
  font-weight: 600;
  padding: 0 0 2px 0 !important;
  border-bottom: 2px solid $g4-onyx !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dygraph-ylabel {
  transform: rotate(-90deg);
}
.dygraph-y2label {
  transform: rotate(90deg);
}

.graph--hasYLabel {
  .dygraph-axis-label-y {
    padding: 0 1px 0 16px !important;
  }
  .dygraph-axis-label-y2 {
    padding: 0 16px 0 1px !important;
  }
}

/* Single Stat Cells */
.single-stat {
  position: absolute;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 2px);
  pointer-events: none;
  border-radius: 3px;
  transition: background-color 0.25s ease, color 0.25s ease;
  @include no-user-select();
  color: $c-laser;

  &.graph-single-stat {
    top: 0;
  }

  > canvas.gauge {
    width: 100% !important;
    height: 100% !important;
  }
}
.dygraph-child > .single-stat {
  z-index: 3;
}
.single-stat--value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: calc(100% - 32px);
  text-align: center;
  font-size: 54px;
  line-height: 54px;
  font-weight: 300;
  color: inherit;
  z-index: 1;

  &.single-stat--small {
    font-weight: 400;
    font-size: 34px;
    line-height: 34px;
  }
}
.single-stat--shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.single-stat--shadow:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 0;
  transform: translate(-50%, -50%);
  box-shadow: fade-out($g2-kevlar, 0.3) 0 0 50px 30px;
  z-index: -1;
}
.single-stat--small .single-stat--shadow:after {
  box-shadow: fade-out($g2-kevlar, 0.3) 0 0 30px 10px;
}

/*
  Legend Styles
  ------------------------------------------------------------------------------
*/
.dygraph-child-container .dygraph-legend {
  display: none !important; // hide default legend
}
.dygraph-legend {
  background-color: $g0-obsidian;
  display: block !important;
  position: absolute;
  padding: 11px;
  z-index: 500;
  border-radius: 3px;
  min-width: 350px;
  user-select: text;
  transform: translateX(-50%);
  box-shadow: 0 0 10px 2px $g2-kevlar;

  &.hidden {
    display: none !important;
  }

  // Arrow (default is on top of legend aka below graph)
  &:after {
    content: '';
    position: absolute;
    border-width: 8px;
    border-style: solid;
    border-color: transparent;
  }
  &.dygraph-legend--top:after {
    top: -16px;
    border-bottom-color: $g0-obsidian;
    left: 50%;
    transform: translateX(-50%);
  }
  &.dygraph-legend--bottom:after {
    bottom: -16px;
    border-top-color: $g0-obsidian;
    left: 50%;
    transform: translateX(-50%);
  }
  &.dygraph-legend--left:after {
    left: -16px;
    border-right-color: $g0-obsidian;
    top: 50%;
    transform: translateY(-50%);
  }
  &.dygraph-legend--right:after {
    right: -16px;
    border-left-color: $g0-obsidian;
    top: 50%;
    transform: translateY(-50%);
  }
}
.dygraph-legend--header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  > .btn {
    margin-left: 4px;
  }
}
.dygraph-legend--timestamp {
  margin-right: 8px;
  height: 30px;
  font-size: 13px;
  white-space: nowrap;
  line-height: 30px;
  font-weight: 600;
  color: $g13-mist;
  flex: 1 0 0%;
}
.dygraph-legend--filter {
  flex: 1 0 0%;
  margin-top: 8px;
}
.dygraph-legend--divider {
  width: 100%;
  margin: 8px 0;
  height: 2px;
  background-color: $g5-pepper;
}
.dygraph-legend--contents {
  font-size: 13px;
  color: $g15-platinum;
  font-weight: 600;
  line-height: 13px;
  max-height: 123px;
  overflow-y: auto;
  @include custom-scrollbar-round($g0-obsidian, $g3-castle);
}
.dygraph-legend--row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  opacity: 0.5;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  padding: 3px 0;

  span {
    font-weight: 900;
    padding: 0;
    white-space: nowrap;
  }
  figure {
    white-space: nowrap;
    padding-left: 10px;
    font-family: $code-font;
  }

  &.highlight {
    opacity: 1;
    background-color: $g3-castle;
    figure {
      color: $g20-white;
    }
  }
  &.highlight:only-child {
    background-color: transparent;
  }
}

/* Sorting Buttons */
.sort-btn {
  position: relative;
}
.sort-btn--arrow {
  position: absolute;
  top: 8px;
  right: 8px;
  height: calc(100% - 16px);
  width: 2px;
  background-color: $g20-white;
  transform: rotate(0deg);
  transition: transform 0.25s ease;

  &:after {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%) scaleX(0.7);
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $g20-white;
  }
}
.sort-btn--asc .sort-btn--arrow {
  transform: rotate(180deg);
}
.sort-btn--top,
.sort-btn--bottom {
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  color: $g20-white;
  left: 6px;
}
.sort-btn--top {
  top: -5px;
}
.sort-btn--bottom {
  bottom: -6px;
}
