/*
    Time Machine Visualization
    ----------------------------------------------------------------------------
*/

.time-machine-visualization {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @include gradient-v($g2-kevlar, $g0-obsidian);
}

.time-machine--graph {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: $g3-castle;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
}

.time-machine--graph-header {
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center
}

.time-machine--graph-header .nav.nav-tablist {
  width: 180px;

  li {
    justify-content: center;
    flex: 1 0 0;
    white-space: nowrap;
  }
}

.time-machine--graph-body {
  padding: 0 16px 8px 16px;
  flex: 1 0 0;
}