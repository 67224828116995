/*
  Fill Query
  ------------------------------------------------------------------------------
  Small widget for supplying a fill() value in a query
*/

.fill-query {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 8px; // Assumes it is to the right of GROUP BY TIME
}
.fill-query--label {
  order: 1;
  margin: 0;
  color: $g12-forge;
  padding: 0 6px;
  height: 30px;
  line-height: 30px;
}
.fill-query--dropdown {
  order: 2;
}
.fill-query--dropdown .dropdown-selected {
  font-family: $code-font;
}
.fill-query--dropdown .dropdown-menu .dropdown-item a {
  font-family: $code-font;
}
input.form-control.fill-query--input {
  width: 70px;
  order: 3;
  margin: 0;
  font-weight: 600;
  border-color: $g7-graphite;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover {border-color: $g8-storm;}
  &:focus {border-color: $c-pool;}

  & + .fill-query--dropdown .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
